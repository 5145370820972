<template>
  <basic-container style="height: 100vh">
    <van-nav-bar class="van-nav-bar" :title="process.processDefinitionName" />
    <div class="statusIcon">
      <template v-if="process.processIsFinished === 'reject'">
        <i v-if="process.status === 'todo'">
          <img src="@/assets/statusIcon/audit.png" alt="" />
        </i>
        <i v-if="process.status === 'done'">
          <img src="@/assets/statusIcon/refuse.png" alt="" />
        </i>
      </template>
      <template v-if="process.processIsFinished === 'unfinished'">
        <i v-if="process.status === 'todo'">
          <img src="@/assets/statusIcon/audit.png" alt="" />
        </i>
        <i v-if="process.status === 'done'">
          <img src="@/assets/statusIcon/processed.png" alt="" />
        </i>
      </template>
      <template v-if="process.processIsFinished === 'finished'">
        <i v-if="process.status === 'todo'">
          <img src="@/assets/statusIcon/audit.png" alt="" />
        </i>
        <i v-if="process.status === 'done'">
          <img src="@/assets/statusIcon/pass.png" alt="" />
        </i>
      </template>
    </div>
    <div class="mainBox">
      <van-collapse v-model="activeNames">
        <van-collapse-item
          :title="$t(`cip.desk.workflow.title.indexHeadFirst`)"
          name="1"
        >
          <div class="value">
            <iframe
              class="build_matrix_box"
              id="iframeForm"
              ref="iframeForm"
              :src="iframe_url"
              frameborder="0"
              style="width: 1920px; height: calc(100vh - 185px)"
            ></iframe>
          </div>
        </van-collapse-item>
        <van-collapse-item
          :title="$t(`cip.desk.workflow.title.indexHeadSecond`)"
          name="2"
        >
          <div class="value2">
            <wf-flow :flow="flow"></wf-flow>
          </div>
        </van-collapse-item>
        <!-- <van-collapse-item
          :title="$t(`cip.desk.workflow.title.indexHeadThird`)"
          name="3"
        >
          <wf-design
            ref="bpmn"
            style="height: 500px"
            :options="bpmnOption"
          ></wf-design
        ></van-collapse-item> -->
      </van-collapse>
    </div>

    <div style="display: none">
      <van-tabs v-model="activeName">
        <van-tab
          name="first"
          :title="$t(`cip.desk.workflow.title.indexHeadFirst`)"
        >
          <el-card shadow="never" style="calc(100vh - 160px);">
            <div id="printBody" ref="container" style="overflow: scroll">
              <!-- 自定义表单区域 -->

              <iframe
                class="build_matrix_box"
                id="iframeForm"
                ref="iframeForm"
                :src="iframe_url"
                frameborder="0"
                style="width: 1920px; height: calc(100vh - 185px)"
              ></iframe>
              <!-- <el-scrollbar>
                <form-layout
                  ref="formLayout"
                  :column="option.column"
                  :dataForm="form"
                ></form-layout>

              </el-scrollbar> -->

              <!-- 自定义表单区域 -->
            </div>
          </el-card>
          <el-card
            shadow="never"
            style="margin-top: 20px; display: none"
            v-if="process.status == 'todo'"
          >
            <wf-examine-form
              ref="examineForm"
              :comment.sync="comment"
              :process="process"
              @user-select="handleUserSelect"
            ></wf-examine-form>
          </el-card>
        </van-tab>
        <van-tab
          name="second"
          :title="$t(`cip.desk.workflow.title.indexHeadSecond`)"
        >
          <el-card shadow="never" class="exchangeCard" style="padding: 10px 0">
            <wf-flow :flow="flow"></wf-flow>
          </el-card>
        </van-tab>
        <van-tab
          name="third"
          :title="$t(`cip.desk.workflow.title.indexHeadThird`)"
        >
          <template v-if="activeName == 'third'">
            <el-card
              shadow="never"
              class="exchangeCard"
              style="padding: 10px 0"
            >
              <wf-design
                ref="bpmn"
                style="height: 500px"
                :options="bpmnOption"
              ></wf-design>
            </el-card>
          </template>
        </van-tab>
      </van-tabs>
    </div>

    <!-- <van-popup
      v-model="showDialog"
      :title="rejectTitle"
      position="bottom"
      :style="{ height: '30%' }"
    >
      <div style="font-size: 12px">{{ rejectTitle }}</div>
      <div style="font-size: 12px">sss</div>
    </van-popup> -->

    <van-action-sheet
      :round="false"
      style=""
      v-model="showDialog"
      :title="rejectTitle"
    >
      <div class="dialog_content">
        <div>
          <vant-wf-examine-form
            ref="examineForm"
            :comment.sync="comment"
            :rejectTitle="rejectTitle"
            :process="process"
            @user-select="handleUserSelect"
          ></vant-wf-examine-form>
        </div>
        <div class="agreeBtn">
          <div
            class="agree"
            v-if="!isReject"
            v-loading="loading"
            @click="handleExamineBefore(true)"
          >
            <span
              v-if="['recall', 'reject'].includes(process.processIsFinished)"
              >重新提交</span
            >
            <span v-else>确认同意</span>
          </div>
          <div
            class="agree"
            v-if="isReject"
            v-loading="loading"
            @click="handleExamineBefore(false)"
          >
            确认拒绝
          </div>
        </div>
      </div>
    </van-action-sheet>

    <vantButton
      :loading="submitLoading"
      :button-list="buttonList"
      :process="process"
      :comment="comment"
      @showDialog="showDialogFun"
      @examine="handleExamineBefore"
      @temporary-save="handleTemporary"
      @user-select="handleUserSelect"
      @print="handlePrint"
      @rollback="handleRollbackTask"
      @terminate="handleTerminateProcess"
      @withdraw="handleWithdrawTask"
    ></vantButton>

    <!-- 人员选择弹窗 -->
    <user-select
      ref="user-select"
      :check-type="checkType"
      :default-checked="defaultChecked"
      @onConfirm="handleUserSelectConfirm"
    ></user-select>
  </basic-container>
</template>

<script>
import VantWfExamineForm from "@/views/plugin/workflow/process/components/vantExamForm.vue";
import WfButton from "@/views/plugin/workflow/process/components/button.vue";
import WfFlow from "@/views/plugin/workflow/process/components/flow.vue";
import userSelect from "@/views/plugin/workflow/process/components/user-select";
import vantButton from "@/views/plugin/workflow/process/components/vantButton.vue";

import exForm from "@/views/plugin/workflow/mixins/ex-form";
import theme from "@/views/plugin/workflow/mixins/theme";
import request from "@/router/axios";
export default {
  mixins: [exForm, theme],
  components: { userSelect, VantWfExamineForm, WfButton, WfFlow, vantButton },
  watch: {
    "$route.query.p": {
      handler(val) {
        if (val) {
          const param = JSON.parse(Buffer.from(val, "base64").toString());
          const { taskId, processInsId } = param;
          if (taskId && processInsId) this.getDetail(taskId, processInsId);
        }
      },
      immediate: true,
    },
  },
  data() {
    return {
      activeNames: ["2"],
      pass: false,
      isValidateForm: false,
      activeName: "first",
      iframe_url: "",
      option: {
        column: [
          {
            label: "属性1",
            prop: "attr1",
            disabled: true,
          },
          {
            label: "属性2",
            prop: "attr2",
            disabled: true,
          },
          {
            label: "属性3",
            prop: "attr3",
            disabled: true,
          },
          {
            label: "属性4",
            prop: "attr4",
            disabled: true,
          },
        ],
      },
      defaults: {},
      form: {},
      stagingTaskData: [],
      // option: {}, // 表单配置
      vars: [], // 需要提交的字段
      isStagingTask: false, // 是否是暂存
      submitLoading: false, // 提交时按钮loading
      callObj: {
        taskDefinitionKey: "",
        id: "",
        type: "",
      },

      taskForm: {
        approval: 1, //审批意见 0拒绝，1同意
        comment: "", //审批意见信息
        operator: "", //执行人名称
        operatorId: "", //执行人id
        taskId: "", //任务id
        taskInfo: "", //业务相关内容--签名图片url
      },
      rejectTitle: "",
      isReject: true,
      checked: true,
      showDialog: false,
      moreButtons: false,
      userSelectDialog: false,
      displacement: {
        scale: 1,
        originScale: 1,
        moveable: false,
        pageX: 0,
        pageY: 0,
        pageX2: 0,
        pageY2: 0,
      },
    };
  },
  mounted() {
    this.waiting = false;
    function changeView() {
      const clientWidth = window.screen.width;
      const dpr = window.devicePixelRatio;
      const vp = document.createElement("meta");
      document.documentElement.style.fontSize =
        clientWidth > 414 ? "20px" : (20 * dpr * clientWidth) / 360 + "px";
      vp.name = "viewport";
      vp.content = `initial-scale=1, user-scalable=yes, width=device-width`;
      var m = document.getElementsByTagName("meta")[0];
      m.parentNode.insertBefore(vp, m);
      console.log(dpr, "dpr", m, "vp", vp);
    }
    changeView();
    // this.iframe_url="/safetyenment/#/projectDepartment/securityWeekly/edit?type=2&id="+this.form.id
    this.touchScaleFun();
    this.$nextTick(() => {
      this.setupTouchEvents();
    });
  },
  methods: {
    setupTouchEvents() {
      const container = this.$refs.container;
      let initialDistance = 0;
      let currentDistance = 0;
      let initialFontSize = 100;
      let currentFontSize = 100;

      container.addEventListener("touchstart", (event) => {
        if (event.touches.length === 2) {
          const x1 = event.touches[0].clientX;
          const y1 = event.touches[0].clientY;
          const x2 = event.touches[1].clientX;
          const y2 = event.touches[1].clientY;

          initialDistance = Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2);
          initialFontSize = currentFontSize;
        }
      });

      container.addEventListener("touchmove", (event) => {
        if (event.touches.length === 2) {
          const x1 = event.touches[0].clientX;
          const y1 = event.touches[0].clientY;
          const x2 = event.touches[1].clientX;
          const y2 = event.touches[1].clientY;

          currentDistance = Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2);
          const scale = currentDistance / initialDistance;
          currentFontSize = initialFontSize * scale;

          const iframe = this.$refs.iframeForm;
          iframe.style.fontSize = `${currentFontSize}%`;
        }
      });
    },
    touchScaleFun() {
      let that = this;
      this.$nextTick(() => {
        // 获取放大或缩小的区域DOM
        let matrix_box = document.querySelector(".build_matrix_box");

        let slide_box = document.querySelector(".build_matrix_box .slide-box"); //滑动区域
        matrix_box.addEventListener("touchstart", function (event) {
          var touches = event.touches;
          var events = touches[0];
          var events2 = touches[1];

          // event.preventDefault();

          // 第一个触摸点的坐标
          that.displacement.pageX = events.pageX;
          that.displacement.pageY = events.pageY;

          that.displacement.moveable = true;

          if (events2) {
            that.displacement.pageX2 = events2.pageX;
            that.displacement.pageY2 = events2.pageY;
          }

          that.displacement.originScale = that.displacement.scale || 1;
          // console.log(that.displacement);
        });
        document.addEventListener("touchmove", function (event) {
          if (!that.displacement.moveable) {
            return;
          }

          event.preventDefault();

          var touches = event.touches;
          var events = touches[0];
          var events2 = touches[1];
          // 双指移动
          if (events2) {
            // 第2个指头坐标在touchmove时候获取
            if (!that.displacement.pageX2) {
              that.displacement.pageX2 = events2.pageX;
            }
            if (!that.displacement.pageY2) {
              that.displacement.pageY2 = events2.pageY;
            }

            // 双指缩放比例计算
            var zoom =
              that.getDistance(
                {
                  x: events.pageX,
                  y: events.pageY,
                },
                {
                  x: events2.pageX,
                  y: events2.pageY,
                }
              ) /
              that.getDistance(
                {
                  x: that.displacement.pageX,
                  y: that.displacement.pageY,
                },
                {
                  x: that.displacement.pageX2,
                  y: that.displacement.pageY2,
                }
              );
            // 应用在元素上的缩放比例
            var newScale = that.displacement.originScale * zoom;
            // 最大缩放比例限制
            // if (newScale > 1) {
            //  newScale = 1;
            //  }

            // 最小缩放比例限制
            // if (newScale < 1) {
            //   newScale = 1;
            // }
            // 记住使用的缩放值
            that.displacement.scale = newScale;
            // 图像应用缩放效果
            // console.log(newScale);
            matrix_box.style.transform = "scale(" + newScale + ")";
            // 设置旋转元素的基点位置
            matrix_box.style.transformOrigin = "0px 0px 0px";

            // 设置左右滑动空间
            slide_box.style.width =
              matrix_box.getBoundingClientRect().width + "px";
            slide_box.style.height =
              matrix_box.getBoundingClientRect().height + "px";
          }
        });
      });
    },
    showDialogFun(isReject, rejectTitle) {
      this.isReject = isReject;
      this.rejectTitle = rejectTitle;
      this.showDialog = true;
    },

    // 获取任务详情
    getDetail(taskId, processInsId) {
      this.getTaskDetail(taskId, processInsId).then((res) => {
        console.log("appDetail");
        const { process, form } = res;
        const { variables, status } = process;
        // console.log("processDetail", res)
        let { taskForm } = form;
        // console.log("process", process)
        // console.log("taskForm", taskForm)
        // console.log("variables", variables)
        this.form = variables;
        // console.log(this.form,'this.form');
        // console.log("status", status) // todo待办 done已办

        // 自行处理相关逻辑
        // this.iframe_url="/safetyenment/#/projectDepartment/securityWeekly/edit?isApprove=true&type=2&id="+this.form.id
        this.isValidateForm = process.isValidateForm;
        if (process.formUrl.indexOf("?") >= 0) {
          this.iframe_url = process.formUrl + `&id=${this.form.id}`;
        } else {
          this.iframe_url = process.formUrl + `?id=${this.form.id}`;
        }
        this.callObj = {
          taskDefinitionKey: process.taskDefinitionKey,
          id: variables.id,
          type: "",
        };
        this.waiting = false; // 关闭骨架屏

        this.$nextTick(() => {
          // console.log(window,'window');
          window.addEventListener(
            "message",
            (e) => {
              // 监听 message 事件
              // console.log(e,'监听 message 事件------------------------')
              //   console.log(this.isStagingTask, 'this.isStagingTask-----------------------------------------')
              if (e.data.type == "checkFinish") {
                if (this.isStagingTask) {
                  this.handleTemporarySave(e.data.data);
                } else {
                  this.handleExamine(this.pass, e.data.data);
                }
              }
            },
            false
          );

          let iframe = document.getElementById("iframeForm"); // 监控标签ID
          // iframe.src = 'https://www.baidu.com/'
          if (iframe.attachEvent) {
            // IE下
            iframe.attachEvent("onload", function () {
              //    后续操作
              console.log(33333);
            });
          } else {
            iframe.onload = function () {
              //    后续操作
              console.log(
                document
                  .getElementById("iframeForm")
                  .contentWindow.document.getElementsByTagName("meta"),
                "-=-=-=-=---------"
              );

              const vp = document.createElement("meta");
              vp.name = "viewport";
              vp.content = `initial-scale=0.5, user-scalable=yes, width=device-width, maximum-scale=1.0, minimum-scale=0.5`;
              var m = document
                .getElementById("iframeForm")
                .contentWindow.document.getElementsByTagName("meta")[0];
              m.parentNode.insertBefore(vp, m);
              console.log("dpr", m, "vp", vp);
            };
          }
        });
      });
    },
    handleTemporary() {
      this.isStagingTask = true;
      let data = {
        type: "check",
      };
      this.$refs.iframeForm.contentWindow.postMessage(data, "*");
    },
    //暂存
    handleTemporarySave(data) {
      this.submitLoading = true;
      const variables = {};
      this.vars.forEach((v) => {
        if (v != "comment" && this.form[v]) variables[v] = this.form[v];
      });
      Object.assign(variables, { data: data });
      this.handleSaveTask(variables)
        .then(() => {
          this.$message.success("处理成功");
          wx.miniProgram.navigateBack();
          window.close();
        })
        .catch(() => {
          this.submitLoading = false;
        });
    },
    handleExamineBefore(pass) {
      this.isStagingTask = false;
      // console.log(this.isValidateForm,'this.isValidateForm------------------------------------------------');
      if (this.isValidateForm) {
        // console.log('走true了');
        this.pass = pass;
        let data = {
          type: "check",
        };
        this.$refs.iframeForm.contentWindow.postMessage(data, "*");
      } else {
        this.handleExamine(pass);
      }
    },
    // 审核
    handleExamine(pass, backData) {
      this.submitLoading = true;
      // this.$refs.formLayout.form.validate((valid, done) => {
      // if (valid) {
      const variables = {};
      this.vars.forEach((v) => {
        if (v != "comment" && this.form[v]) variables[v] = this.form[v];
      });
      Object.assign(variables, { data: backData });
      this.handleCompleteTask(pass, variables)
        .then(() => {
          this.$message.success("处理成功");
          wx.miniProgram.navigateBack();
          if (pass) {
            this.callObj.type = "审批";
          } else {
            this.callObj.type = "驳回";
          }

          window.close();
        })
        .catch(() => {
          // done()
          this.submitLoading = false;
        });
      //   } else {
      //     done()
      //     this.submitLoading = false
      //   }
      // })
    },
    // 回调地址
    callbackFn() {
      request({
        url: "/api/sinoma-sm/safetyWeeklyReport/callBack",
        method: "post",
        data: this.callObj,
      })
        .then
        // window.close()
        // this.handleCloseTag('/plugin/workflow/process/todo')
        ();
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  width: 100%;
  height: 50px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px 10px 0;
}
::v-deep .avue-affix {
  position: relative !important;
}
::v-deep .van-tabs__line {
  background-color: #0087ff !important;
}
.dialog_content {
  // padding: 10px 30px 20px;
  .agreeBtn {
    box-sizing: border-box;
    bottom: 0;
    padding: 15px;
    /* margin-top: 15px; */
    /* padding-bottom: 20px; */
    > div {
      text-align: center;
      height: 40px;
      font-size: 15px;
      font-weight: 600;
      border-radius: 23px;
      line-height: 40px;
    }
    .reject {
      background: #f5faff;

      border: 1px solid #086de5;

      color: #086de5;
    }
    .agree {
      background: #086de5;
      color: #fff;
      border: 1px solid #086de5;
    }
  }
  .van-cell::after {
    border: none;
  }
  .opt_btns {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    > button {
      margin: 0 15px;
    }
  }
  .qz {
    margin-top: 5px;
    box-sizing: border-box;
    width: 85px;
    height: 40px;
    background: #086de5;
    border-radius: 20px;
    border: 1px solid #086de5;
    font-size: 13px;
    font-weight: 600;
    color: #ffffff;
  }
  .qz1 {
    background: #f5faff;
    border: 1px solid #086de5;
    color: #086de5;
  }
  .qzimg {
    box-sizing: border-box;
    width: 100%;
    border: 1px solid #d4e4f5;
    border-radius: 10px;
    margin-bottom: 10px;
  }
  .forward {
    margin-top: 14px;

    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    color: #999999;

    span {
      margin-left: 15px;
    }
    .user {
      color: #086de5;
    }
  }
}
.van-action-sheet__header {
  font-size: 18px;

  font-weight: 600;
  color: #000000;
  .van-icon-cross:before {
    content: "\e6a7";
    color: #000000;
  }
}

.van-nav-bar {
  position: relative;
}

.statusIcon {
  position: fixed;
  right: 10px;
  z-index: 10;
  top: 90px;
  img {
    width: 100px;
    height: 100px;
    display: block;
  }
}

.exchangeCard {
  overflow: auto;
  height: calc(100vh - 180px);
}
</style>
<style lang="scss" scoped>
.mainBox {
  width: 100vw;
  height: calc(100vh - 110px);
  font-size: 14px;
  overflow: scroll;
  .value {
    // margin: 5px;
    height: 40vh;
    overflow: scroll;
  }
  .value2 {
    // margin: 5px;
    height: 74vh;
    overflow: scroll;
  }
}
</style>
<style scoped>
.mainBox >>> .van-collapse-item__content {
  padding: 0 !important;
}
</style>
